<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeDate"
        class="filter-item"
        style="display: inline-flex"
      ></el-date-picker>
      <el-input
        v-model="listQuery.title"
        placeholder="请输入标题"
        style="width: 200px"
        class="filter-item"
        clearable
      />

      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="原贴标题" width="95" align="center">
        <template slot-scope="scope">
          {{ JSON.parse(scope.row.post_snap).title }}
        </template>
      </el-table-column>
      <el-table-column label="举报人ID" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.user_id }}
        </template>
      </el-table-column>
      <el-table-column label="举报人头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user"
            :src="scope.row.user.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.nickname : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="举报标签" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.report_type ? scope.row.report_type.title : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="描述" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.content }}
        </template>
      </el-table-column>
      <el-table-column label="时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="帖子状态" min-width="110" align="center">
        <template slot-scope="scope" v-if="scope.row.post">
          <el-tag v-if="scope.row.post.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope" v-if="scope.row.post">
          <el-button
            v-if="scope.row.post.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.post_id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.post_id, '上架')"
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        title: "",
        start_at: "",
        end_at: "",
      },
      dateArr: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    changeDate(data) {
      this.dateArr = data;
      this.listQuery.start_at = data[0];
      this.listQuery.end_at = data[1];
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/post/reportLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该帖子, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/post/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
